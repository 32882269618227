import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import './App.css';

// 合约地址
const contractAddress1 = "0x15d7Df4512979B54954108723F994108633B40F2";
const contractAddress2 = "0x87CC5d67b23B66BFe3b70D4f1aADa1Cb0380c500"
// 合约ABI
const abi1 = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "AccessControlBadConfirmation",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "neededRole",
                "type": "bytes32"
            }
        ],
        "name": "AccessControlUnauthorizedAccount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CheckpointUnorderedInsertion",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ECDSAInvalidSignature",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "length",
                "type": "uint256"
            }
        ],
        "name": "ECDSAInvalidSignatureLength",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "ECDSAInvalidSignatureS",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "increasedSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "cap",
                "type": "uint256"
            }
        ],
        "name": "ERC20ExceededCap",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "increasedSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "cap",
                "type": "uint256"
            }
        ],
        "name": "ERC20ExceededSafeSupply",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "allowance",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "needed",
                "type": "uint256"
            }
        ],
        "name": "ERC20InsufficientAllowance",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "needed",
                "type": "uint256"
            }
        ],
        "name": "ERC20InsufficientBalance",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "approver",
                "type": "address"
            }
        ],
        "name": "ERC20InvalidApprover",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "cap",
                "type": "uint256"
            }
        ],
        "name": "ERC20InvalidCap",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "ERC20InvalidReceiver",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "ERC20InvalidSender",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "ERC20InvalidSpender",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }
        ],
        "name": "ERC2612ExpiredSignature",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "signer",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "ERC2612InvalidSigner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "timepoint",
                "type": "uint256"
            },
            {
                "internalType": "uint48",
                "name": "clock",
                "type": "uint48"
            }
        ],
        "name": "ERC5805FutureLookup",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ERC6372InconsistentClock",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "EnforcedPause",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ExpectedPause",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "currentNonce",
                "type": "uint256"
            }
        ],
        "name": "InvalidAccountNonce",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidShortString",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint8",
                "name": "bits",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "SafeCastOverflowedUintDowncast",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "str",
                "type": "string"
            }
        ],
        "name": "StringTooLong",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "expiry",
                "type": "uint256"
            }
        ],
        "name": "VotesExpiredSignature",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "delegator",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "fromDelegate",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "toDelegate",
                "type": "address"
            }
        ],
        "name": "DelegateChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "delegate",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "previousVotes",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "newVotes",
                "type": "uint256"
            }
        ],
        "name": "DelegateVotesChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "EIP712DomainChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "previousAdminRole",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "newAdminRole",
                "type": "bytes32"
            }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleGranted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleRevoked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "BURNER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "CLOCK_MODE",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DOMAIN_SEPARATOR",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MINTER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "PAUSER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "burnFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cap",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint32",
                "name": "pos",
                "type": "uint32"
            }
        ],
        "name": "checkpoints",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint48",
                        "name": "_key",
                        "type": "uint48"
                    },
                    {
                        "internalType": "uint208",
                        "name": "_value",
                        "type": "uint208"
                    }
                ],
                "internalType": "struct Checkpoints.Checkpoint208",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "clock",
        "outputs": [
            {
                "internalType": "uint48",
                "name": "",
                "type": "uint48"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "delegatee",
                "type": "address"
            }
        ],
        "name": "delegate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "delegatee",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "nonce",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "expiry",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "delegateBySig",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "delegates",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "eip712Domain",
        "outputs": [
            {
                "internalType": "bytes1",
                "name": "fields",
                "type": "bytes1"
            },
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "version",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "chainId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "verifyingContract",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "salt",
                "type": "bytes32"
            },
            {
                "internalType": "uint256[]",
                "name": "extensions",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "timepoint",
                "type": "uint256"
            }
        ],
        "name": "getPastTotalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "timepoint",
                "type": "uint256"
            }
        ],
        "name": "getPastVotes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleAdmin",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "getVotes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "hasRole",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "nonces",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "numCheckpoints",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "callerConfirmation",
                "type": "address"
            }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const abi2 = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_token",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_paymentContract",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "OwnableInvalidOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "OwnableUnauthorizedAccount",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "beneficiary",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint64",
                "name": "startTimestamp",
                "type": "uint64"
            },
            {
                "internalType": "uint64",
                "name": "durationDays",
                "type": "uint64"
            }
        ],
        "name": "CreateVestingWallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DAYS_TO_SECONDS",
        "outputs": [
            {
                "internalType": "uint64",
                "name": "",
                "type": "uint64"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DECIMALS_MULTIPLIER",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "beneficiaries",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllVestingInfo",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "beneficiariesList",
                "type": "address[]"
            },
            {
                "internalType": "address[]",
                "name": "walletAddressesList",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "totalLockedAmounts",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "totalReleasedAmounts",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "totalUnReleasedAmounts",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "totalReleasableAmounts",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getVestingInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "walletAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "lockedAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "releasedAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "unreleasedAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "releasableAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "hasVestingWallet",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paymentContract",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "beneficiary",
                "type": "address"
            }
        ],
        "name": "release",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "release",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "contract TCMToken",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "vestingWallets",
        "outputs": [
            {
                "internalType": "contract VestingWallet",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

function App() {
    // 合约1参数
    const [account, setAccount] = useState(null); // 存储当前账户地址
    const [token1, setToken1] = useState(null); // 存储合约1实例
    const [balance, setBalance] = useState(null); // 存储账户余额
    const [symbol, setSymbol] = useState(null); // 存储代币符号
    const [decimals, setDecimals] = useState(null); // 存储代币精度
    const [spender, setSpender] = useState(null); // 存储授权地址
    const [cap, setCap] = useState(null); // 存储代币发行总量
    const [totalSupply, setTotalSupply] = useState(null); // 存储已经发行的代币数量
    const [remainingSupply, setRemainingSupply] = useState(null); // 还未发行的代币数量
    const [approveAmount, setApproveAmount] = useState(null); // 存储授权数量
    const [mintAmount, setMintAmount] = useState(null); // 存储铸币数量
    const [mintTo, setMintTo] = useState(null); // 存储铸币目标地址
    const [inputAccount, setInputAccount] = useState(null); // 用于输入要查询余额的账户地址
    const [inputBalance, setInputBalance] = useState(null); // 存储查询结果
    const [roleGrant, setRoleGrant] = useState(null); // 用于选择要授权的角色
    const [grantAccount, setGrantAccount] = useState(null); // 存储要授权角色的账户地址
    const [toTransfer, setToTransfer] = useState(null); // 存储转账目标账户
    const [transferAmount, setTransferAmount] = useState(null); // 存储转账金额
    const [fromAccount, setFromAccount] = useState(null); // 存储 transferFrom 的发起账户
    const [toAccount, setToAccount] = useState(null); // 存储 transferFrom 的目标账户
    const [transferFromAmount, setTransferFromAmount] = useState(null); // 存储 transferFrom 的金额
    const [burnAmount, setBurnAmount] = useState(null); // 存储销毁的金额
    const [burnFromAmount, setBurnFromAmount] = useState(null); // 存储从指定账户销毁的金额
    const [burnFromAccount, setBurnFromAccount] = useState(null); // 存储指定账户地址
    const [revokeRoleAccount, setRevokeRoleAccount] = useState(null); // 用于输入要撤销角色的账户地址
    const [roleToRevoke, setRoleToRevoke] = useState(null); // 用于选择要撤销的角色
    const [hasMinterRole, setMintRole] = useState(null); //用于存储账户当前是否为铸币角色
    //const [hasPauserRole, setPauseRole] = useState(null); //用于存储账户当前是否为暂停合约角色
    const [hasBurnerRole, setBurnRole] = useState(null); //用于存储账户当前是否为销毁代币角色
    const [hasAdminRole, setAdminRole] = useState(null); //用于存储账户当前是否为销毁代币角色


    // 合约2参数
    const [token2, setToken2] = useState(null); // 存储合约2实例
    const [beneficiary, setBeneficiary] = useState(null); // 存储受益人地址
    const [amount, setAmount] = useState(null); // 存储锁仓数量
    const [startTimestamp, setStartTimestamp] = useState(null); // 存储锁仓开始时间
    const [durationDays, setDurationDays] = useState(null); // 存储锁仓持续天数
    const [beneficiariesList, setBeneficiariesList] = useState([]); // 受益人（列表）
    const [walletAddressesList, setWalletAddressesList] = useState([]); // 钱包地址（列表）
    const [totalLockedAmounts, setTotalLockedAmounts] = useState([]); // 总锁仓金额（列表）
    const [totalReleasedAmounts, setTotalReleasedAmounts] = useState([]); // 已释放金额（列表）
    const [totalUnReleasedAmounts, setTotalUnReleasedAmounts] = useState([]); // 未释放金额（列表）
    const [totalReleasableAmounts, setTotalReleasableAmounts] = useState([]); // 可释放金额（列表）
    const [walletAddress, setWalletAddress] = useState(null); // 钱包地址
    const [lockedAmount, setLockedAmount] = useState(null); // 总锁仓金额
    const [releasedAmount, setReleasedAmount] = useState(null); // 已释放金额
    const [unreleasedAmount, setUnreleasedAmount] = useState(null); // 未释放金额
    const [releasableAmount, setReleasableAmount] = useState(null); // 可释放金额
    const [isOwner, setIsOwner] = useState(false); // 是否为合约所有者
    // 控制显示锁仓信息的状态
    const [showAllVestingInfo, setShowAllVestingInfo] = useState(false);
    // 零地址
    const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
    const [isLocker, setIsLocker] = useState(false); // 是否有锁仓钱包

    // 角色映射
    const roles = {
        BURNER_ROLE: "BURNER_ROLE",
        PAUSER_ROLE: "PAUSER_ROLE",
        MINTER_ROLE: "MINTER_ROLE",
        DEFAULT_ADMIN_ROLE: "DEFAULT_ADMIN_ROLE",
    };

    const getSigner = async () => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const address = await signer.getAddress();
            setAccount(address);

            // 获取合约1实例
            const contract1 = new ethers.Contract(contractAddress1, abi1, signer);
            setToken1(contract1);

            // 并行获取代币精度、单位、账户余额、总供应量、已发行代币数量、最大供应量等信息
            const [decimals, symbol, balance, totalSupplyValue, capValue] = await Promise.all([
                contract1.decimals(),
                contract1.symbol(),
                contract1.balanceOf(address),
                contract1.totalSupply(),
                contract1.cap()
            ]);

            setDecimals(decimals);
            setSymbol(symbol);
            setBalance(ethers.formatUnits(balance, decimals));
            setTotalSupply(ethers.formatUnits(totalSupplyValue, decimals));
            setCap(ethers.formatUnits(capValue, decimals));

            // 计算未发行代币数量
            const remainingSupplyValue = ethers.formatUnits(capValue, decimals) - ethers.formatUnits(totalSupplyValue, decimals);
            setRemainingSupply(remainingSupplyValue);

            // 并行检查账户角色
            const [hasMinterRole, /*hasPauserRole*/, hasBurnerRole, hasAdminRole] = await Promise.all([
                contract1.hasRole(await contract1.MINTER_ROLE(), address),
                //contract1.hasRole(await contract1.PAUSER_ROLE(), address),
                contract1.hasRole(await contract1.BURNER_ROLE(), address),
                contract1.hasRole(await contract1.DEFAULT_ADMIN_ROLE(), address)
            ]);

            setMintRole(hasMinterRole);
            //setPauseRole(hasPauserRole);
            setBurnRole(hasBurnerRole);
            setAdminRole(hasAdminRole);

            // 获取合约2实例
            const contract2 = new ethers.Contract(contractAddress2, abi2, signer);
            setToken2(contract2);

            // 获取合约所有者地址
            const ownerAddress = await contract2.owner();
            setIsOwner(ownerAddress.toLowerCase() === address.toLowerCase());

            // 获取锁仓钱包信息
            const hasWallet = await contract2.hasVestingWallet();

            setIsLocker(hasWallet);

            if (!hasWallet) {
                console.log("没有锁仓钱包");
                setWalletAddress(ZERO_ADDRESS);
                setLockedAmount("0");
                setReleasedAmount("0");
                setUnreleasedAmount("0");
                setReleasableAmount("0");

                return;
            }

            const vestingInfo = await contract2.getVestingInfo();

            // 解构返回的数据
            const { walletAddress, lockedAmount, releasedAmount, unreleasedAmount, releasableAmount } = vestingInfo;

            // 更新状态
            setWalletAddress(walletAddress);

            setLockedAmount(lockedAmount ? ethers.formatUnits(lockedAmount, decimals) : "0");
            setReleasedAmount(releasedAmount ? ethers.formatUnits(releasedAmount, decimals) : "0");
            setUnreleasedAmount(unreleasedAmount ? ethers.formatUnits(unreleasedAmount, decimals) : "0");
            setReleasableAmount(releasableAmount ? ethers.formatUnits(releasableAmount, decimals) : "0");
        } catch (error) {
            console.error("获取信息时发生错误：", error);
            alert("发生错误，请稍后再试！");
        }
    };

    useEffect(() => {
        if (window.ethereum) {
            // 初次加载时获取账户信息
            getSigner();

            // 监听账户变化
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length > 0) {
                    setAccount(accounts[0]);
                    getSigner(); // 更新合约和余额
                    window.location.reload();
                } else {
                    setAccount(null);
                    setBalance(null);
                }
            });

            // 监听网络变化
            window.ethereum.on('chainChanged', (_chainId) => {
                window.location.reload(); // 当网络变化时重新加载页面
            });
        }

        // 清理函数，移除监听事件
        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('accountsChanged', () => { });
                window.ethereum.removeListener('chainChanged', () => { });
            }
        };
    }, []);

    // 查询余额
    const getBalance = async () => {
        if (token1 && account) {
            const balance = await token1.balanceOf(account);
            setBalance(ethers.formatUnits(balance, decimals));
        }
    };

    // 获取代币信息（总量、已发行、未发行）
    const getTokenInfo = async (contract, decimals) => {
        if (token1 && account) {
            // 获取代币总量
            const totalSupplyValue = await contract.totalSupply();
            setTotalSupply(ethers.formatUnits(totalSupplyValue, decimals));

            // 获取代币上限（已发行代币数量）
            const capValue = await contract.cap();
            setCap(ethers.formatUnits(capValue, decimals));

            // 计算未发行代币数量
            const remainingSupplyValue = ethers.formatUnits(capValue, decimals) - ethers.formatUnits(totalSupplyValue, decimals);
            setRemainingSupply(remainingSupplyValue);
        }
    };

    // 查询指定账户余额
    const getBalanceOfAccount = async () => {
        if (token1 && inputAccount) {
            try {
                const balance = await token1.balanceOf(inputAccount);
                setInputBalance(ethers.formatUnits(balance, decimals));
            } catch (error) {
                alert("获取余额时出错： " + error.message);
            }
        } else {
            alert("请输入有效的账户地址！");
        }
    };

    // 转账功能
    const transferTokens = async () => {
        if (token1 && account && toTransfer && transferAmount) {
            try {
                const amount = ethers.parseUnits(transferAmount, decimals);
                const tx = await token1.transfer(toTransfer, amount);
                await tx.wait();
                alert("转账成功！");
                getBalance();
                getTokenInfo(token1, decimals);

                // 清空输入框的值
                setToTransfer('');
                setTransferAmount('');
            } catch (error) {
                alert("转移代币时出错： " + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 授权转账功能
    const transferFromTokens = async () => {
        if (token1 && fromAccount && toAccount && transferFromAmount) {
            try {
                const amount = ethers.parseUnits(transferFromAmount, decimals);
                const tx = await token1.transferFrom(fromAccount, toAccount, amount);
                await tx.wait();
                alert("授权转账成功！");
                getBalance();
                getTokenInfo(token1, decimals);

                // 清空输入框的值
                setFromAccount('');
                setToAccount('');
                setTransferFromAmount('');
            } catch (error) {
                alert("授权转账时出错：" + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 铸币功能
    const mintTokens = async () => {
        if (token1 && account && mintAmount && mintTo) {
            try {
                const amount = ethers.parseUnits(mintAmount, decimals);
                const tx = await token1.mint(mintTo, amount);
                await tx.wait();
                alert("铸币成功！");
                getBalance();
                await getTokenInfo(token1, decimals);

                // 清空输入框的值
                setMintAmount('');
                setMintTo('');
            } catch (error) {
                alert("铸币时出错: " + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 销毁功能
    const burnTokens = async () => {
        if (token1 && account && burnAmount) {
            try {
                const amount = ethers.parseUnits(burnAmount, decimals);
                const tx = await token1.burn(amount); // 调用burn方法
                await tx.wait();
                alert("销毁代币成功!");
                getBalance();
                getTokenInfo(token1, decimals);

                // 清空输入框的值
                setBurnAmount('');
            } catch (error) {
                alert("销毁代币时出错: " + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 从指定账户销毁功能
    const burnFromTokens = async () => {
        if (token1 && burnFromAccount && burnFromAmount) {
            try {
                const amount = ethers.parseUnits(burnFromAmount, decimals); // 转换金额
                const tx = await token1.burnFrom(burnFromAccount, amount); // 调用burnFrom方法
                await tx.wait();
                alert("销毁代币成功!");
                getBalance();
                getTokenInfo(token1, decimals);

                // 清空输入框的值
                setBurnFromAccount('');
                setBurnFromAmount('');
            } catch (error) {
                alert("销毁代币时出错: " + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };


    // 授权功能
    const approveTokens = async () => {
        if (token1 && account && spender && approveAmount) {
            try {
                const amount = ethers.parseUnits(approveAmount, decimals);
                const tx = await token1.approve(spender, amount);
                await tx.wait();
                alert("授权代币成功！");
                getBalance();
                getTokenInfo(token1, decimals);

                // 清空输入框的值
                setSpender('');
                setApproveAmount('');
            } catch (error) {
                alert("授权代币时出错: " + error.message);
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 授予角色功能
    const grantRoleToAccount = async () => {
        if (!hasAdminRole) {
            alert("您不是Admin用户，无法给其他用户授权角色！");
            return;
        }
        if (token1 && roleGrant && grantAccount) {
            try {
                let roleBytes;
                // 根据选择的角色调用对应的函数获取 bytes32 角色值
                if (roleGrant === "BURNER_ROLE") {
                    roleBytes = await token1.BURNER_ROLE();
                } else if (roleGrant === "PAUSER_ROLE") {
                    roleBytes = await token1.PAUSER_ROLE();
                } else if (roleGrant === "MINTER_ROLE") {
                    roleBytes = await token1.MINTER_ROLE();
                } else if (roleGrant === "DEFAULT_ADMIN_ROLE") {
                    roleBytes = await token1.DEFAULT_ADMIN_ROLE();
                } else {
                    alert("选择的角色无效！");
                    return;
                }

                // 调用 grantRole 方法授予角色
                const tx = await token1.grantRole(roleBytes, grantAccount);
                await tx.wait();
                alert(`角色 ${roleGrant} 已授权给 ${grantAccount}`);
                setRoleGrant("");
                setGrantAccount("");
            } catch (error) {
                alert("授权角色时出错: " + (error.message || error));
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 撤销角色功能
    const revokeRoleFromAccount = async () => {
        if (!hasAdminRole) {
            alert("您不是Admin用户，无法撤销其他用户角色！");
            return;
        }
        if (token1 && revokeRoleAccount && roleToRevoke) {
            try {
                // 获取对应角色的 bytes32 值
                let roleBytes;
                if (roleToRevoke === "BURNER_ROLE") {
                    roleBytes = await token1.BURNER_ROLE();
                } else if (roleToRevoke === "PAUSER_ROLE") {
                    roleBytes = await token1.PAUSER_ROLE();
                } else if (roleToRevoke === "MINTER_ROLE") {
                    roleBytes = await token1.MINTER_ROLE();
                } else if (roleToRevoke === "DEFAULT_ADMIN_ROLE") {
                    roleBytes = await token1.DEFAULT_ADMIN_ROLE();
                } else {
                    alert("Invalid role selected.");
                    return;
                }

                // 调用 revokeRole 方法撤销角色
                const tx = await token1.revokeRole(roleBytes, revokeRoleAccount);
                await tx.wait();
                alert(`角色 ${roleToRevoke} 已从 ${revokeRoleAccount} 撤销`);
                setRevokeRoleAccount(""); // 清空输入
                setRoleToRevoke(""); // 清空角色选择
            } catch (error) {
                alert("撤销角色时出错: " + (error.message || error));
            }
        } else {
            alert("请填写所有字段！");
        }
    };

    // 创建锁仓钱包
    const createVestingWallet = async () => {
        if (!isOwner) {
            alert("您不是合约的所有者，无法创建锁仓钱包！");
            return;
        }
        if (!beneficiary || !amount || !startTimestamp || !durationDays) {
            alert('请填写所有字段!');
            return;
        }

        try {
            const amountInWei = ethers.parseUnits(amount, decimals);
            const startTimestampInt = parseInt(startTimestamp, 10);
            const durationDaysInt = parseInt(durationDays, 10);
            const durationSeconds = durationDaysInt;

            // 调用合约的 CreateVestingWallet 方法
            const tx = await token2.CreateVestingWallet(beneficiary, amountInWei, startTimestampInt, durationSeconds);
            console.log(tx);
            await tx.wait();
            alert('锁仓钱包创建成功！');
            window.location.reload();
        } catch (error) {
            console.error(error);
            alert('创建锁仓钱包失败，账户余额不足或该受益人已存在锁仓钱包！');
        }
    };

    // 获取所有锁仓钱包信息
    const getAllVestingInfo = async () => {
        if (!isOwner) {
            alert("您不是合约的所有者，无法查询所有锁仓钱包信息！");
            return;
        }

        try {
            // 调用合约的 getAllVestingInfo 函数获取锁仓信息
            const vestingInfo = await token2.getAllVestingInfo();

            // 解构返回的数据并格式化
            setBeneficiariesList(vestingInfo.beneficiariesList); // 受益人列表
            setWalletAddressesList(vestingInfo.walletAddressesList); // 锁仓钱包地址列表
            setTotalLockedAmounts(vestingInfo.totalLockedAmounts.map(amount => ethers.formatUnits(amount, decimals))); // 格式化总锁仓金额
            setTotalReleasedAmounts(vestingInfo.totalReleasedAmounts.map(amount => ethers.formatUnits(amount, decimals))); // 格式化已释放金额
            setTotalUnReleasedAmounts(vestingInfo.totalUnReleasedAmounts.map(amount => ethers.formatUnits(amount, decimals))); // 格式化未释放金额
            setTotalReleasableAmounts(vestingInfo.totalReleasableAmounts.map(amount => ethers.formatUnits(amount, decimals))); // 格式化可释放金额

            setShowAllVestingInfo(true);
        } catch (error) {
            console.error("获取锁仓信息失败:", error); // 如果获取信息失败，输出错误
        }
    };

    // 释放锁仓钱包的方法
    const releaseTokens = async () => {
        if (!token2) {
            alert('合约尚未加载，请稍后再试!');
            return;
        }

        try {
            // 判断是否需要传递受益人地址
            if (beneficiary) {
                console.log('调用带 beneficiary 参数的 release 方法');
                const tx = await token2.release(beneficiary);  // 调用带 beneficiary 参数的 release 方法
                console.log('Transaction:', tx);
                await tx.wait();  // 等待交易确认
            } else {
                console.log('调用不带参数的 release 方法');
                const tx = await token2.release();  // 调用不带参数的 release 方法
                console.log('Transaction:', tx);
                await tx.wait();  // 等待交易确认
            }

            alert('锁仓金额已释放!');
            getBalance();
            getVestingInfo();
        } catch (error) {
            console.error('Release error:', error);
            alert('释放失败，您还没有锁仓钱包！');
        }
    };

    const getVestingInfo = async () => {
        if (!token2) {
            alert('合约尚未加载，请稍后再试!');
            return;
        }

        try {
            // 调用合约的 getVestingInfo 方法
            const vestingInfo = await token2.getVestingInfo();

            // 解构返回的数据
            const { walletAddress, lockedAmount, releasedAmount, unreleasedAmount, releasableAmount } = vestingInfo;

            // 更新状态
            setWalletAddress(walletAddress);
            setLockedAmount(ethers.formatUnits(lockedAmount, decimals));  // 格式化金额为代币单位
            setReleasedAmount(ethers.formatUnits(releasedAmount, decimals));
            setUnreleasedAmount(ethers.formatUnits(unreleasedAmount, decimals));
            setReleasableAmount(ethers.formatUnits(releasableAmount, decimals));
            setIsLocker(true);
        } catch (error) {
            console.error('获取锁仓信息失败:', error);
            alert('没有查询到您的锁仓钱包信息！');
        }
    };

    const formatLocalDatetime = (timestamp) => {
        const localDate = new Date(timestamp * 1000); 
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };    


    return (
        <div className="app-container">
            <h1>TCM Token Dapp</h1>
            <div className="info-container">
                <div className="left-column">
                    <h3>代币信息</h3>
                    <h4>发行代币总量: {cap ? `${cap} ${symbol}` : "Loading..."}</h4>
                    <h4>已发行代币数量: {totalSupply ? `${totalSupply} ${symbol}` : "Loading..."}</h4>
                    <h4>未发行代币数量: {remainingSupply !== null ? `${remainingSupply} ${symbol}` : "Loading..."}</h4>

                </div>

                <div className="right-column">
                    <h3>账户信息</h3>
                    <h4>账户地址: {account ? `${account}` : "Loading..."}</h4>
                    <h4>账户余额: {balance ? `${balance} ${symbol}` : "Loading..."}</h4>

                    {isLocker && (
                        <>
                            <div className="vesting-info">
                                <h3>锁仓钱包信息</h3>
                                <h4>钱包地址：{walletAddress ? `${walletAddress}` : "Loading..."}</h4>
                                <h4>总锁仓钱包金额（含已释放）：{lockedAmount ? `${lockedAmount} ${symbol}` : "Loading..."}</h4>
                                <h4>锁仓钱包已释放金额：{releasedAmount ? `${releasedAmount} ${symbol}` : "Loading..."}</h4>
                                <h4>锁仓钱包未释放金额：{unreleasedAmount ? `${unreleasedAmount} ${symbol}` : "Loading..."}</h4>
                                <h4>锁仓钱包可释放金额：{releasableAmount ? `${releasableAmount} ${symbol}` : "Loading..."}</h4>
                            </div>
                        </>
                    )}
                    <div className="button-container">
                        <button onClick={getBalance} className="button">刷新账户信息</button>
                        {isLocker && (

                            <button onClick={getVestingInfo} className="button">刷新锁仓钱包信息</button>
                        )}
                    </div>
                </div>
            </div>

            {hasMinterRole && (
                <>
                    <h2>铸币功能(Mint)</h2>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="铸币账户地址"
                            value={mintTo}
                            onChange={(e) => setMintTo(e.target.value)}
                            className="input-field"
                        />
                        <input
                            type="text"
                            placeholder="铸币金额"
                            value={mintAmount}
                            onChange={(e) => setMintAmount(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={mintTokens} className="button">铸币</button>
                    </div>
                </>
            )}

            {hasBurnerRole && (
                <>
                    <h2>销毁功能(Burn)</h2>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="销毁金额"
                            value={burnAmount}
                            onChange={(e) => setBurnAmount(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={burnTokens} className="button">销毁代币</button>
                    </div>

                    <h2>从指定账户销毁功能(BurnFrom)</h2>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="指定账户地址"
                            value={burnFromAccount}
                            onChange={(e) => setBurnFromAccount(e.target.value)}
                            className="input-field"
                        />
                        <input
                            type="number"
                            placeholder="销毁金额"
                            value={burnFromAmount}
                            onChange={(e) => setBurnFromAmount(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={burnFromTokens} className="button">从账户销毁代币</button>
                    </div>
                </>
            )}

            <h2>授权金额功能(Approve)</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="授权账户地址"
                    value={spender}
                    onChange={(e) => setSpender(e.target.value)}
                    className="input-field"
                />
                <input
                    type="text"
                    placeholder="授权金额"
                    value={approveAmount}
                    onChange={(e) => setApproveAmount(e.target.value)}
                    className="input-field"
                />
                <button onClick={approveTokens} className="button">授权</button>
            </div>

            {hasAdminRole && (
                <>
                    <h2>通过账户查询余额(BalanceOf)</h2>
                    <div className="input-container">
                        {inputBalance !== null && (
                            <div className="balance-result">
                                <h3>{inputAccount} : {inputBalance} {symbol}</h3>
                            </div>
                        )}
                        <input
                            type="text"
                            placeholder="输入账户地址"
                            value={inputAccount}
                            onChange={(e) => {
                                setInputAccount(e.target.value);
                                setInputBalance(null);
                            }}
                            className="input-field"
                        />
                        <button onClick={getBalanceOfAccount} className="button">查询余额</button>
                    </div>
                </>
            )}


            <h2>转账功能(Transfer)</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="目标账户地址"
                    value={toTransfer}
                    onChange={(e) => setToTransfer(e.target.value)}
                    className="input-field"
                />
                <input
                    type="number"
                    placeholder="金额"
                    value={transferAmount}
                    onChange={(e) => setTransferAmount(e.target.value)}
                    className="input-field"
                />
                <button onClick={transferTokens} className="button">转账</button>
            </div>

            <h2>授权转账功能 (TransferFrom)</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="发起账户地址"
                    value={fromAccount}
                    onChange={(e) => setFromAccount(e.target.value)}
                    className="input-field"
                />
                <input
                    type="text"
                    placeholder="目标账户地址"
                    value={toAccount}
                    onChange={(e) => setToAccount(e.target.value)}
                    className="input-field"
                />
                <input
                    type="number"
                    placeholder="金额"
                    value={transferFromAmount}
                    onChange={(e) => setTransferFromAmount(e.target.value)}
                    className="input-field"
                />
                <button onClick={transferFromTokens} className="button">授权转账</button>
            </div>

            {hasAdminRole && (
                <>
                    <h2>角色授权功能(GrantRole)</h2>
                    <div className="input-container">
                        <select
                            value={roleGrant}
                            onChange={(e) => setRoleGrant(e.target.value)}
                            className="input-field"
                        >
                            <option value="">选择角色</option>
                            {Object.entries(roles).map(([roleKey, roleName]) => (
                                <option key={roleKey} value={roleName}>{roleName}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder="授权账户地址"
                            value={grantAccount}
                            onChange={(e) => setGrantAccount(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={grantRoleToAccount} className="button">授权角色</button>
                    </div>

                    <h2>撤销角色功能(RevokeRole)</h2>
                    <div className="input-container">
                        <select
                            value={roleToRevoke}
                            onChange={(e) => setRoleToRevoke(e.target.value)}
                            className="input-field"
                        >
                            <option value="">选择要撤销的角色</option>
                            {Object.entries(roles).map(([roleKey, roleName]) => (
                                <option key={roleKey} value={roleName}>{roleName}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder="输入账户地址"
                            value={revokeRoleAccount}
                            onChange={(e) => setRevokeRoleAccount(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={revokeRoleFromAccount} className="button">撤销角色</button>
                    </div>
                </>
            )}

            {isOwner && (
                <>
                    <h2>创建锁仓钱包</h2>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="请输入受益人地址"
                            value={beneficiary}
                            onChange={(e) => setBeneficiary(e.target.value)}
                            className="input-field"
                        />
                        <input
                            type="text"
                            placeholder="请输入受益金额"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="input-field"
                        />
                        <input
                            type="datetime-local"
                            placeholder="请输入金额开始释放时间"
                            value={startTimestamp ? formatLocalDatetime(startTimestamp) : ''}
                            onChange={(e) => {
                                const selectedDate = new Date(e.target.value);
                                const timeStampInSeconds = Math.floor(selectedDate.getTime() / 1000);
                                setStartTimestamp(timeStampInSeconds);
                            }}
                            className="input-field"
                        />
                        <input
                            type="text"
                            placeholder="请输入释放周期（天）"
                            value={durationDays}
                            onChange={(e) => setDurationDays(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={createVestingWallet} className="button">创建锁仓钱包</button>
                    </div>
                </>
            )}

            {isOwner && (
                <>
                    <h2>查询所有锁仓钱包信息</h2>
                    <div className="input-container">
                        <button onClick={getAllVestingInfo} className="button">查询所有锁仓钱包</button>

                        {showAllVestingInfo && (
                            <div className="vesting-info-container">
                                <h3>锁仓钱包信息</h3>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>受益人</th>
                                                <th>钱包地址</th>
                                                <th>总锁仓金额</th>
                                                <th>已释放金额</th>
                                                <th>未释放金额</th>
                                                <th>可释放金额</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {beneficiariesList.map((beneficiary, index) => (
                                                <tr key={index}>
                                                    <td>{beneficiary}</td>
                                                    <td>{walletAddressesList[index]}</td>
                                                    <td>{totalLockedAmounts[index]} {symbol}</td>
                                                    <td>{totalReleasedAmounts[index]} {symbol}</td>
                                                    <td>{totalUnReleasedAmounts[index]} {symbol}</td>
                                                    <td>{totalReleasableAmounts[index]} {symbol}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            {isLocker && (
                <>
                    <h2>释放锁仓金额</h2>
                    <div className="input-container">
                        <button onClick={releaseTokens} className="button">释放锁仓金额</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default App;